footer {
  background-color: #303030;
  margin-top: 1rem;
}

.footer_content {
  padding-block: 1rem;
  width: 90%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_content img {
  width: 50px;
}

.footer_content > p {
  font-size: clamp(12px, 3vw, 18px);
}

.links {
  display: flex;
  gap: 1rem;
}

.links a {
  color: white;
  font-size: 1.6rem;
}

@media screen and (max-width: 750px) {
  .footer_content {
    flex-direction: column;
    gap: 0.4rem;
  }
  .footer_content > p {
    order: 2;
    margin-top: 10px;
    text-align: center;
  }
  .links {
    order: 1;
  }
  .footer_content img {
    width: 40px;
  }
}
