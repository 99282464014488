@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --gold: #e4b05e;
  --gold-dark: #b7893e;
  --light-gold: #faf2cf;
}

::selection {
  background: #faf2cf;
  background: var(--light-gold);
  color: #303030;
}

* {
  font-family: 'Poppins', 'Noto Sans Arabic', sans-serif;
}

body {
  background: #303030;
  color: white;
  overflow-x: hidden;
  min-height: 100vh;
}

.content {
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 240px);
}

.App::before {
  content: '';
  position: fixed;
  width: 200%;
  height: 200%;
  background-repeat: no-repeat;
  background-image: url(/static/media/outlined.91bee93c.svg);
  background-position: 200px -350px;
  -webkit-transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
          transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
  opacity: 0.1;
  z-index: -1;
}

.back_logo {
  position: absolute;
  -webkit-transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
          transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
  opacity: 0.1;
}

@media screen and (max-width: 768px) {
  .content {
    width: 95%;
  }
  .App::before {
    width: 150%;
    height: 150%;
    background-position: 0px 80px;
    -webkit-transform: matrix(1, -0.4, 0.4, 1, 0, 0);
            transform: matrix(1, -0.4, 0.4, 1, 0, 0);
  }
}

@-webkit-keyframes slideInFromRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80%);
            transform: translateX(-80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80%);
            transform: translateX(-80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-30px);
            transform: translatey(-30px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-30px);
            transform: translatey(-30px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@-webkit-keyframes floatBut {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(30px);
            transform: translatey(30px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes floatBut {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(30px);
            transform: translatey(30px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  padding-top: 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.post {
  padding: 0;
  color: white;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.post:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.post a {
  text-decoration: none;
  color: inherit;
}

.post_category {
  padding: 0 0.5rem;
  font-weight: 600;
}

.post img {
  margin-block: 0.5rem;
  width: 100%;
  aspect-ratio: 16/9;
}

.post_date {
  padding: 0 0.5rem;
  margin: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}

.post_title_small {
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 1.5rem;
}

.post_subtitle_small {
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 1rem;
  opacity: 0.7;
}

@media screen and (max-width: 650px) {
  .post:hover {
    -webkit-transform: none;
            transform: none;
  }

  .post + .post {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 1rem;
  }
}

.contact_me {
  text-align: center;
  font-size: clamp(2rem, 5vw, 3.2rem);
}

.contact_form {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(100, 100, 100, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.form_label {
  font-size: 1.4rem;
}

.form_input + .form_label {
  margin-top: 2rem;
}

.form_input {
  border: none;
  border-bottom: 2px solid #ccc;
  background: transparent;
  min-height: 3.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  transition: border-bottom 0.3s ease-in-out;
  color: whitesmoke;
}

.form_input:focus {
  outline: none;
  border-bottom: 5px solid #ccc;
}
.form_input::-webkit-input-placeholder {
  opacity: 0.5;
}
.form_input::placeholder {
  opacity: 0.5;
}

textarea {
  resize: vertical;
  height: 10rem;
}

.form_button {
  margin-top: 2rem;
  min-height: 3.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  border: 3px solid var(--gold);
  border-radius: 10px;
  background: none;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.form_button:hover,
.form_button:focus {
  background: rgba(150, 150, 150, 0.1);
  outline: none;
}

footer {
  background-color: #303030;
  margin-top: 1rem;
}

.footer_content {
  padding-block: 1rem;
  width: 90%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_content img {
  width: 50px;
}

.footer_content > p {
  font-size: clamp(12px, 3vw, 18px);
}

.links {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.links a {
  color: white;
  font-size: 1.6rem;
}

@media screen and (max-width: 750px) {
  .footer_content {
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
  }
  .footer_content > p {
    order: 2;
    margin-top: 10px;
    text-align: center;
  }
  .links {
    order: 1;
  }
  .footer_content img {
    width: 40px;
  }
}

.me {
  margin-right: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.hello {
  font-family: 'Cinzel Decorative', cursive;
  z-index: 10;
  color: var(--gold);
  font-size: clamp(40px, 7vw, 5.4rem);
  margin-bottom: 0;
  padding-top: 1rem;
  text-align: right;
  z-index: 1;
}

.hello > * {
  font-family: inherit;
}

.hello2 {
  padding-top: 0;
  margin: 0;
}

.also {
  text-align: right;
  font-size: 2rem;
  width: 30ch;
  margin-top: 0;
  font-size: clamp(1.2rem, 3vw, 2rem);
}

.hovering {
  position: absolute;
  top: 5rem;
  left: 5rem;
  width: 300px;
  -webkit-animation: float 3s infinite ease-in-out;
          animation: float 3s infinite ease-in-out;
  z-index: 2;
}

.buttons {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
}

.scroll_button {
  border: 2px solid var(--gold);
  border-radius: 30px;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  color: var(--gold);
  background: transparent;
  transition: background 0.2s;
  cursor: pointer;
  margin-block: 5rem;
}

.scroll_button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.scroll_button:focus {
  outline: none;
}

@media screen and (max-width: 1300px) {
  .hovering {
    top: 8rem;
    left: 1rem;
    width: 250px;
  }
}

@media screen and (max-width: 1000px) {
  .buttons {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .me {
    align-items: center;
  }
  .me > * {
    text-align: center;
  }
  .also {
    width: 90%;
    margin-block: 2rem;
  }
  .hovering {
    display: none;
  }
  .scroll_button {
    margin-block: 3rem;
  }
  .scroll_button + .scroll_button {
    margin-block: 0;
    margin-bottom: 5rem;
  }
  .animation {
    -webkit-animation: floatBut infinite ease-in-out 2s;
            animation: floatBut infinite ease-in-out 2s;
  }
}

.skills {
    display: flex;
    margin-top: 8rem;
    grid-gap: 50px;
    gap: 50px;
    justify-content: space-between;
    padding-inline: 5%;
}

.skills * {
    transition: all 2s ease;
}

.skills_container {
    -webkit-animation: slideInFromRight 1.5s ease-in-out;
            animation: slideInFromRight 1.5s ease-in-out;
    flex-basis: 55%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.skill>* {
    margin: 0;
    padding: 0;
}

.skill h4 {
    padding-left: 1rem;
    width: 30%;
    font-weight: 500;
    flex-basis: 25%;
}

.skill_bar {
    flex-basis: 75%;
    width: 100%;
    background-color: var(--light-gold);
}

.skill_bar_percentage {
    padding: 0.4rem;
    background-color: var(--gold);
    text-align: start;
    color: black;
}

@media screen and (max-width: 1000px) {
    .skills {
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        margin-top: 4rem;
    }
    .skills_container {
        grid-gap: 15px;
        gap: 15px;
        padding: 0;
    }
    .skill h4 {
        flex-basis: 30%;
    }
    .skill_bar {
        flex-basis: 70%;
    }
    .skill h4 {
        font-size: 0.8rem;
    }
}
.who_am_i {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    -webkit-animation: slideInFromLeft 1.5s ease-in-out;
            animation: slideInFromLeft 1.5s ease-in-out;
}

.image_wrapper {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    background: var(--gold);
    box-sizing: border-box;
    -webkit-clip-path: circle(50% at center);
            clip-path: circle(50% at center);
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.image_wrapper:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.profile_picture {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 192px;
    height: 192px;
    -webkit-clip-path: circle(50% at center);
            clip-path: circle(50% at center);
}

#who {
    font-weight: 500;
    letter-spacing: 2px;
    margin-top: 1.4rem;
    margin-bottom: -0.6rem;
}

#about_me {
    font-weight: 300;
    font-size: 1.2rem;
    opacity: 0.8;
    text-align: center;
}

.link {
    color: var(--gold);
    text-decoration: none;
    font-weight: 400;
}

@media screen and (max-width: 1000px) {
    .who_am_i {
        padding: 1rem;
    }
}
.navbar {
  /* background: transparent; */
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-width: 1500px;
}

.navbar-logo img {
  height: 60px;
  transition: all 0.3s ease;
}

.navbar-logo img:hover,
.nav-link:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
}

.nav-link {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  transition: all 0.3s ease;
}

.fa-bars {
  color: #fff;
  padding-top: -100px;
}

.nav-link-mobile,
.menu-icon {
  display: none;
}

.not_at_top {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.fa-external-link-alt {
  font-size: 1rem;
  margin-left: 0.6rem;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: -120%;
    /* opacity: 1; */
    transition: all 0.5s ease;
    grid-gap: 0;
    gap: 0;
    z-index: 50;
    justify-content: flex-start;
  }
  .nav-menu.active {
    background: #303030;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
  .nav-link {
    display: table;
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-grow: 1;
  }
  .nav-link:hover {
    background-color: #fff;
    color: #242424;
    border-bottom: none;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .navbar-logo img {
    position: absolute;
    top: 0;
    left: 30px;
    -webkit-transform: translate(15%, 35%);
            transform: translate(15%, 35%);
  }
  .navbar-logo img:hover {
    -webkit-transform: translate(15%, 35%);
            transform: translate(15%, 35%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-120%, 65%);
            transform: translate(-120%, 65%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

.projects_wrapper {
  /* background-color: burlywood; */
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.project_wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.project_wrapper > img {
  width: clamp(200px, 30vw, 300px);
  height: 100%;
  object-fit: cover;
  border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
  aspect-ratio: 3/2;
}

.project_wrapper:hover > img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
}

.project_wrapper:hover {
  background: rgba(200, 200, 200, 0.2);
}

.project_text {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  padding-inline: 1.8rem;
  justify-content: space-around;
}

.project_text > * {
  margin: 0;
}

.project_text > h2 {
  font-size: clamp(1.8rem, 4vw, 2.2rem);
  margin-bottom: -0.5rem;
}

.project_text > p {
  font-size: clamp(1rem, 2vw, 1.4rem);
}

.project_text > a {
  color: white;
  font-size: 1.2rem;
  width: 100%;
  text-align: right;
}

.more {
  background: var(--gold);
  border: none;
  color: #303030;
  font-size: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0.8rem;
  border-radius: 0.4rem;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.more:hover {
  background: #f4c271;
}

.more:focus {
  outline: var(--gold-dark) solid 0.2rem;
}

@media screen and (max-width: 600px) {
  .project_wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-inline: 0.4rem;
  }
  .project_wrapper > img {
    width: 90%;
  }
  .project_text {
    padding-top: 1rem;
  }
}

body.active-modal {
  overflow-y: hidden;
}

.overlay {
  width: 100vw;
  height: 100vh;
  inset: 0;
  top: 0;
  position: fixed;
  z-index: 10;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 50;
  background: rgb(30 30 30 / 0.2);
  padding: 14px 28px;
  border-radius: 0.5rem;
  width: 90vw;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.4rem;
}

.modal-header h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-top: 0.5rem;
}

.modal-header button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  width: 100%;
  margin: 1rem;

  display: flex;

  padding: clamp(0.5rem, 3vw, 1.5rem);
  padding-top: clamp(0.25rem, 2.5vw, 0.85rem);
}

.modal-body p {
  width: 100%;
}

.modal-body img {
  width: 100%;
  margin: 0 auto;
}

.slick-slider {
  width: 65%;
}

.tags_wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 600px) {
  .tags_wrapper {
    justify-content: center;
  }
}

.tag {
  padding: 0.4rem 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: inline-block;
  /* margin-right: 0.4rem; */
  /* margin-top: -0.5rem; */
  border-radius: 0.4rem;
}

.tag > p {
  margin: 0;
}

/*! POST */

.post_content {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
}

.post_header {
  position: relative;
}

.post_image {
  width: 100%;
  aspect-ratio: 16/8;
  object-fit: cover;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  transition: -webkit-filter 0.5s ease;
  transition: filter 0.5s ease;
  transition: filter 0.5s ease, -webkit-filter 0.5s ease;
}

.post_image:hover {
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}

.post_header_text {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  margin: 0;
  padding: 1rem 2.5rem;

  background-color: rgb(255 255 255 / 0.8);
  color: black;
  text-align: center;
  letter-spacing: 0.2rem;
}

.post_title {
  margin: 0;
  font-size: clamp(1.5rem, 4vw, 3.25rem);
}

.post_subtitle {
  font-size: clamp(1rem, 2vw, 1.5rem);
  opacity: 0.7;
  margin-bottom: 0.25rem;
}

.post_body {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 300;
}

.post_body figure {
  width: 80%;
  margin: 0 auto;
}

.post_body img {
  width: 100%;
}

/*! LOADING */

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

/*! CODE */

.post_code {
  border-radius: 10px;
}

.post_code * {
  font-family: monospace;
}

/*! MOVIES */

.movie {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #303030;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  color: black;
  width: 100%;
  margin: 0 auto;
}

.movie .top_row {
  display: flex;
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.movie img {
  max-width: 40%;
  border-radius: 0.5rem;
}

.movie hr {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}

.movie_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title_genres {
  width: 100%;
}

.movie_title {
  font-size: clamp(1.5rem, 3vw, 3rem);
  line-height: 1.1;
  margin-block: 1rem;
}

.genres {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
}

.genre {
  font-size: 1rem;
  padding: 0.25em 0.75em;
  background-color: rgb(0 0 0 / 0.1);
  border-radius: 1rem;
}

.brief {
  white-space: pre-wrap;
  text-align: justify;
  text-justify: distribute-all-lines;
  line-height: 1.75;
  background-color: rgb(255 255 255 / 0.2);
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 1rem;
  margin: 0 auto;
  margin-top: -2.5rem;
  margin-bottom: 1rem;
  width: 85%;
  font-weight: 500;
}

.ratings {
  font-size: clamp(1.125rem, 1.75vw, 2rem);
}

/*! TABLES */

.post_table {
  width: 95%;
  margin: 0 auto;
  border-collapse: collapse;
  letter-spacing: 1px;
}

.post_table th {
  padding: 0.5rem;
  border: 1px solid rgb(0 0 0 / 0.4);
  background-color: #d7a75a;
}

.post_table td {
  padding: 0.5rem;
  border: 1px solid rgb(0 0 0 / 0.4);
}

.post_table .swap_color {
  background-color: rgb(255 255 255 / 0.15);
}

@media screen and (max-width: 768px) {
  .post_image {
    aspect-ratio: 16/9;
  }
  .post_header_text {
    padding: 0.5rem 1rem;
    letter-spacing: 0.1rem;
  }
  .post_subtitle {
    display: none;
  }
  .post_body {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .post_table {
    font-size: 0.825rem;
  }
}

@media screen and (max-width: 650px) {
  .movie .top_row {
    flex-direction: column;
    grid-gap: 0rem;
    gap: 0rem;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .movie_title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .movie_text {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .ratings {
    width: 100%;
  }
  .movie img {
    width: 100%;
    max-width: 100%;
  }
  .genres {
    justify-content: center;
  }
  .brief {
    background-color: transparent;
    padding: 1rem;
    border-radius: 0;
    margin: 0 auto;
    width: auto;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 1rem;
  }
}

