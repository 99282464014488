.skills {
    display: flex;
    margin-top: 8rem;
    gap: 50px;
    justify-content: space-between;
    padding-inline: 5%;
}

.skills * {
    transition: all 2s ease;
}

.skills_container {
    animation: slideInFromRight 1.5s ease-in-out;
    flex-basis: 55%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.skill>* {
    margin: 0;
    padding: 0;
}

.skill h4 {
    padding-left: 1rem;
    width: 30%;
    font-weight: 500;
    flex-basis: 25%;
}

.skill_bar {
    flex-basis: 75%;
    width: 100%;
    background-color: var(--light-gold);
}

.skill_bar_percentage {
    padding: 0.4rem;
    background-color: var(--gold);
    text-align: start;
    color: black;
}

@media screen and (max-width: 1000px) {
    .skills {
        flex-direction: column;
        gap: 20px;
        margin-top: 4rem;
    }
    .skills_container {
        gap: 15px;
        padding: 0;
    }
    .skill h4 {
        flex-basis: 30%;
    }
    .skill_bar {
        flex-basis: 70%;
    }
    .skill h4 {
        font-size: 0.8rem;
    }
}