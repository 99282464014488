.navbar {
  /* background: transparent; */
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-width: 1500px;
}

.navbar-logo img {
  height: 60px;
  transition: all 0.3s ease;
}

.navbar-logo img:hover,
.nav-link:hover {
  transform: scale(1.2);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
}

.nav-link {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  transition: all 0.3s ease;
}

.fa-bars {
  color: #fff;
  padding-top: -100px;
}

.nav-link-mobile,
.menu-icon {
  display: none;
}

.not_at_top {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.fa-external-link-alt {
  font-size: 1rem;
  margin-left: 0.6rem;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: -120%;
    /* opacity: 1; */
    transition: all 0.5s ease;
    gap: 0;
    z-index: 50;
    justify-content: flex-start;
  }
  .nav-menu.active {
    background: #303030;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
  .nav-link {
    display: table;
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    height: fit-content;
    flex-grow: 1;
  }
  .nav-link:hover {
    background-color: #fff;
    color: #242424;
    border-bottom: none;
    transform: scale(1);
  }
  .navbar-logo img {
    position: absolute;
    top: 0;
    left: 30px;
    transform: translate(15%, 35%);
  }
  .navbar-logo img:hover {
    transform: translate(15%, 35%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-120%, 65%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
