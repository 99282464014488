@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300&display=swap');

:root {
  --gold: #e4b05e;
  --gold-dark: #b7893e;
  --light-gold: #faf2cf;
}

::selection {
  background: var(--light-gold);
  color: #303030;
}

* {
  font-family: 'Poppins', 'Noto Sans Arabic', sans-serif;
}

body {
  background: #303030;
  color: white;
  overflow-x: hidden;
  min-height: 100vh;
}

.content {
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 240px);
}

.App::before {
  content: '';
  position: fixed;
  width: 200%;
  height: 200%;
  background-repeat: no-repeat;
  background-image: url(./assets/outlined.svg);
  background-position: 200px -350px;
  transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
  opacity: 0.1;
  z-index: -1;
}

.back_logo {
  position: absolute;
  transform: matrix(0.9, -0.44, 0.44, 0.9, 200, -400);
  opacity: 0.1;
}

@media screen and (max-width: 768px) {
  .content {
    width: 95%;
  }
  .App::before {
    width: 150%;
    height: 150%;
    background-position: 0px 80px;
    transform: matrix(1, -0.4, 0.4, 1, 0, 0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-30px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes floatBut {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(30px);
  }
  100% {
    transform: translatey(0px);
  }
}
