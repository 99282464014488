.who_am_i {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    animation: slideInFromLeft 1.5s ease-in-out;
}

.image_wrapper {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    background: var(--gold);
    box-sizing: border-box;
    clip-path: circle(50% at center);
    transition: transform 0.4s ease-in-out;
}

.image_wrapper:hover {
    transform: scale(1.1);
}

.profile_picture {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 192px;
    height: 192px;
    clip-path: circle(50% at center);
}

#who {
    font-weight: 500;
    letter-spacing: 2px;
    margin-top: 1.4rem;
    margin-bottom: -0.6rem;
}

#about_me {
    font-weight: 300;
    font-size: 1.2rem;
    opacity: 0.8;
    text-align: center;
}

.link {
    color: var(--gold);
    text-decoration: none;
    font-weight: 400;
}

@media screen and (max-width: 1000px) {
    .who_am_i {
        padding: 1rem;
    }
}