.projects_wrapper {
  /* background-color: burlywood; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.project_wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.project_wrapper > img {
  width: clamp(200px, 30vw, 300px);
  height: 100%;
  object-fit: cover;
  border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
  aspect-ratio: 3/2;
}

.project_wrapper:hover > img {
  transform: scale(1.05);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
}

.project_wrapper:hover {
  background: rgba(200, 200, 200, 0.2);
}

.project_text {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-inline: 1.8rem;
  justify-content: space-around;
}

.project_text > * {
  margin: 0;
}

.project_text > h2 {
  font-size: clamp(1.8rem, 4vw, 2.2rem);
  margin-bottom: -0.5rem;
}

.project_text > p {
  font-size: clamp(1rem, 2vw, 1.4rem);
}

.project_text > a {
  color: white;
  font-size: 1.2rem;
  width: 100%;
  text-align: right;
}

.more {
  background: var(--gold);
  border: none;
  color: #303030;
  font-size: 1rem;
  width: fit-content;
  padding: 0.5rem 0.8rem;
  border-radius: 0.4rem;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.more:hover {
  background: #f4c271;
}

.more:focus {
  outline: var(--gold-dark) solid 0.2rem;
}

@media screen and (max-width: 600px) {
  .project_wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-inline: 0.4rem;
  }
  .project_wrapper > img {
    width: 90%;
  }
  .project_text {
    padding-top: 1rem;
  }
}
