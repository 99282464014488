.tag {
  padding: 0.4rem 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: inline-block;
  /* margin-right: 0.4rem; */
  /* margin-top: -0.5rem; */
  border-radius: 0.4rem;
}

.tag > p {
  margin: 0;
}
