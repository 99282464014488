.contact_me {
  text-align: center;
  font-size: clamp(2rem, 5vw, 3.2rem);
}

.contact_form {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(100, 100, 100, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.form_label {
  font-size: 1.4rem;
}

.form_input + .form_label {
  margin-top: 2rem;
}

.form_input {
  border: none;
  border-bottom: 2px solid #ccc;
  background: transparent;
  min-height: 3.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  transition: border-bottom 0.3s ease-in-out;
  color: whitesmoke;
}

.form_input:focus {
  outline: none;
  border-bottom: 5px solid #ccc;
}
.form_input::placeholder {
  opacity: 0.5;
}

textarea {
  resize: vertical;
  height: 10rem;
}

.form_button {
  margin-top: 2rem;
  min-height: 3.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  border: 3px solid var(--gold);
  border-radius: 10px;
  background: none;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.form_button:hover,
.form_button:focus {
  background: rgba(150, 150, 150, 0.1);
  outline: none;
}
