@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');
.me {
  margin-right: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.hello {
  font-family: 'Cinzel Decorative', cursive;
  z-index: 10;
  color: var(--gold);
  font-size: clamp(40px, 7vw, 5.4rem);
  margin-bottom: 0;
  padding-top: 1rem;
  text-align: right;
  z-index: 1;
}

.hello > * {
  font-family: inherit;
}

.hello2 {
  padding-top: 0;
  margin: 0;
}

.also {
  text-align: right;
  font-size: 2rem;
  width: 30ch;
  margin-top: 0;
  font-size: clamp(1.2rem, 3vw, 2rem);
}

.hovering {
  position: absolute;
  top: 5rem;
  left: 5rem;
  width: 300px;
  animation: float 3s infinite ease-in-out;
  z-index: 2;
}

.buttons {
  display: flex;
  gap: 4rem;
}

.scroll_button {
  border: 2px solid var(--gold);
  border-radius: 30px;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  color: var(--gold);
  background: transparent;
  transition: background 0.2s;
  cursor: pointer;
  margin-block: 5rem;
}

.scroll_button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.scroll_button:focus {
  outline: none;
}

@media screen and (max-width: 1300px) {
  .hovering {
    top: 8rem;
    left: 1rem;
    width: 250px;
  }
}

@media screen and (max-width: 1000px) {
  .buttons {
    flex-direction: column;
    gap: 0;
  }
  .me {
    align-items: center;
  }
  .me > * {
    text-align: center;
  }
  .also {
    width: 90%;
    margin-block: 2rem;
  }
  .hovering {
    display: none;
  }
  .scroll_button {
    margin-block: 3rem;
  }
  .scroll_button + .scroll_button {
    margin-block: 0;
    margin-bottom: 5rem;
  }
  .animation {
    animation: floatBut infinite ease-in-out 2s;
  }
}
