body.active-modal {
  overflow-y: hidden;
}

.overlay {
  width: 100vw;
  height: 100vh;
  inset: 0;
  top: 0;
  position: fixed;
  z-index: 10;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background: rgb(30 30 30 / 0.2);
  padding: 14px 28px;
  border-radius: 0.5rem;
  width: 90vw;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.4rem;
}

.modal-header h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-top: 0.5rem;
}

.modal-header button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  width: 100%;
  margin: 1rem;

  display: flex;

  padding: clamp(0.5rem, 3vw, 1.5rem);
  padding-top: clamp(0.25rem, 2.5vw, 0.85rem);
}

.modal-body p {
  width: 100%;
}

.modal-body img {
  width: 100%;
  margin: 0 auto;
}

.slick-slider {
  width: 65%;
}

.tags_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 600px) {
  .tags_wrapper {
    justify-content: center;
  }
}
