.posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding-top: 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.post {
  padding: 0;
  color: white;
  transition: transform 0.3s ease-in-out;
}

.post:hover {
  transform: scale(1.05);
}

.post a {
  text-decoration: none;
  color: inherit;
}

.post_category {
  padding: 0 0.5rem;
  font-weight: 600;
}

.post img {
  margin-block: 0.5rem;
  width: 100%;
  aspect-ratio: 16/9;
}

.post_date {
  padding: 0 0.5rem;
  margin: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}

.post_title_small {
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 1.5rem;
}

.post_subtitle_small {
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 1rem;
  opacity: 0.7;
}

@media screen and (max-width: 650px) {
  .post:hover {
    transform: none;
  }

  .post + .post {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 1rem;
  }
}
