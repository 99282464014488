/*! POST */

.post_content {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
}

.post_header {
  position: relative;
}

.post_image {
  width: 100%;
  aspect-ratio: 16/8;
  object-fit: cover;
  filter: grayscale(0%);
  transition: filter 0.5s ease;
}

.post_image:hover {
  filter: grayscale(50%);
}

.post_header_text {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0;
  padding: 1rem 2.5rem;

  background-color: rgb(255 255 255 / 0.8);
  color: black;
  text-align: center;
  letter-spacing: 0.2rem;
}

.post_title {
  margin: 0;
  font-size: clamp(1.5rem, 4vw, 3.25rem);
}

.post_subtitle {
  font-size: clamp(1rem, 2vw, 1.5rem);
  opacity: 0.7;
  margin-bottom: 0.25rem;
}

.post_body {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 300;
}

.post_body figure {
  width: 80%;
  margin: 0 auto;
}

.post_body img {
  width: 100%;
}

/*! LOADING */

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

/*! CODE */

.post_code {
  border-radius: 10px;
}

.post_code * {
  font-family: monospace;
}

/*! MOVIES */

.movie {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #303030;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  color: black;
  width: 100%;
  margin: 0 auto;
}

.movie .top_row {
  display: flex;
  gap: 2.5rem;
}

.movie img {
  max-width: 40%;
  border-radius: 0.5rem;
}

.movie hr {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}

.movie_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title_genres {
  width: 100%;
}

.movie_title {
  font-size: clamp(1.5rem, 3vw, 3rem);
  line-height: 1.1;
  margin-block: 1rem;
}

.genres {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
}

.genre {
  font-size: 1rem;
  padding: 0.25em 0.75em;
  background-color: rgb(0 0 0 / 0.1);
  border-radius: 1rem;
}

.brief {
  white-space: pre-wrap;
  text-align: justify;
  text-justify: distribute-all-lines;
  line-height: 1.75;
  background-color: rgb(255 255 255 / 0.2);
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 1rem;
  margin: 0 auto;
  margin-top: -2.5rem;
  margin-bottom: 1rem;
  width: 85%;
  font-weight: 500;
}

.ratings {
  font-size: clamp(1.125rem, 1.75vw, 2rem);
}

/*! TABLES */

.post_table {
  width: 95%;
  margin: 0 auto;
  border-collapse: collapse;
  letter-spacing: 1px;
}

.post_table th {
  padding: 0.5rem;
  border: 1px solid rgb(0 0 0 / 0.4);
  background-color: #d7a75a;
}

.post_table td {
  padding: 0.5rem;
  border: 1px solid rgb(0 0 0 / 0.4);
}

.post_table .swap_color {
  background-color: rgb(255 255 255 / 0.15);
}

@media screen and (max-width: 768px) {
  .post_image {
    aspect-ratio: 16/9;
  }
  .post_header_text {
    padding: 0.5rem 1rem;
    letter-spacing: 0.1rem;
  }
  .post_subtitle {
    display: none;
  }
  .post_body {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .post_table {
    font-size: 0.825rem;
  }
}

@media screen and (max-width: 650px) {
  .movie .top_row {
    flex-direction: column;
    gap: 0rem;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .movie_title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .movie_text {
    gap: 0.25rem;
  }
  .ratings {
    width: 100%;
  }
  .movie img {
    width: 100%;
    max-width: 100%;
  }
  .genres {
    justify-content: center;
  }
  .brief {
    background-color: transparent;
    padding: 1rem;
    border-radius: 0;
    margin: 0 auto;
    width: auto;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 1rem;
  }
}
